import React from 'react'
import Logo from '../assets/logo/logo.svg'

const Header = () => {
	return (
		<div className='fixed top-0 h-fit w-full p-4 bg-themeBg border-gray-200 border-b-[1px] shadow-md z-[99]'>
			<p className="text-3xl font-bold text-[#007AFF]">PublicGrants</p>
		</div>
	)
}

export default Header