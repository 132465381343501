import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { navigate } from 'wouter/use-location';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../configs/firebase/config';

const Payment = ({ customer, password, paymentIntent }) => {

	const stripe = useStripe()
  const elements = useElements()

	const [cardholderName, setCardholderName] = useState('TEST');
	const [billingAddress, setBillingAddress] = useState('TEST');
	const [billingCity, setBillingCity] = useState('TEST');
	const [billingState, setBillingState] = useState('TEST');
	const [billingZip, setBillingZip] = useState('TEST');
	const [errorMessage, setErrorMessage] = useState('');

	const [loading, setLoading] = useState(false)

	const handleSubmit = async (event) => {
		event.preventDefault();

		setLoading(true)
	
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			return;
		}
	
		const cardNumberElement = elements.getElement(CardNumberElement);
		const cardExpiryElement = elements.getElement(CardExpiryElement);
		const cardCvcElement = elements.getElement(CardCvcElement);
	
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardNumberElement,
			billing_details: {
				name: cardholderName,
				email: customer,
				address: {
					line1: billingAddress,
					city: billingCity,
					state: billingState,
					postal_code: billingZip,
				},
			},
		});
	
		if (error) {
			console.log('[error]', error);
			setErrorMessage(error.message);
			setLoading(false)
		} else {
			// console.log('[PaymentMethod]', paymentMethod);
			// Here you would pass the paymentMethod.id to your backend to process the payment
			fetch(`${process.env.REACT_APP_PAYMENT_ENDPOINT_DEV}/confirm-payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
					paymentIntent,
          paymentMethodId: paymentMethod.id,
        }),

      })
			.then((res) => res.json())
			.then((data) => {
				if(data.id) {
					setLoading(false)

					createUserWithEmailAndPassword(auth, customer, password)

					navigate('/login')
				}
			})
			setErrorMessage(''); // Clear any errors
		}
	};
	
// Base style for all Card Elements containers
const CARD_ELEMENT_CONTAINER_STYLE = {
  padding: '12px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: '#fff',
  marginBottom: '24px',
  width: '100%',
};

// Style options for individual card elements
const CARD_NUMBER_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffcca5',
    },
  },
};

const CARD_EXPIRY_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffcca5',
    },
  },
};

const CARD_CVC_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffcca5',
    },
  },
};


	return (
		<div className="order__payment__information">
			<div className="input-box payment__information__container">
				<label className="application__label payment__information__label">Cardholder Name:</label>
				<input type="text" name="cardholder_name" required className="application__input payment__information__input" value={cardholderName} onChange={(e) => setCardholderName(e.target.value)} />
			</div>

			<div className="input-box payment__information__container">
				<label className="application__label payment__information__label">Billing Address:</label>
				<input type="text" name="billing_address" required className="application__input payment__information__input" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
			</div>

			<div className="input-box payment__information__container">
				<label className="application__label payment__information__label">City, State, ZIP:</label>
				<div className="payment__information__city-state-zip">
					<input type="text" name="billing_city" placeholder="City" required className="application__input payment__information__input payment__billing__city" value={billingCity} onChange={(e) => setBillingCity(e.target.value)} />
					<input type="text" name="billing_state" placeholder="State" required className="application__input payment__information__input payment__billing__state" value={billingState} onChange={(e) => setBillingState(e.target.value)} />
					<input type="text" name="billing_zip" placeholder="ZIP" required className="application__input payment__information__input payment__billing__zip" value={billingZip} onChange={(e) => setBillingZip(e.target.value)} />
				</div>
			</div>

			<div style={CARD_ELEMENT_CONTAINER_STYLE}>
				<label>
					Credit Card Number
					<CardNumberElement options={CARD_NUMBER_ELEMENT_OPTIONS} />
				</label>
			</div>

			<div style={CARD_ELEMENT_CONTAINER_STYLE}>
				<label>
					Expiration Date
					<CardExpiryElement options={CARD_EXPIRY_ELEMENT_OPTIONS} />
				</label>
			</div>

			<div style={CARD_ELEMENT_CONTAINER_STYLE}>
				<label>
					CVC
					<CardCvcElement options={CARD_CVC_ELEMENT_OPTIONS} />
				</label>
			</div>

			<input type="hidden" name="send_login_text" value="1" />
			<input type="hidden" name="texting_phone" value="" />

			<button
			 	className='mt-[25px] ml-auto mr-auto rounded p-2 px-8 text-white font-bold bg-themeColor w-fit cursor-pointer hover:bg-themeColorDark disabled:bg-opacity-50 disabled:hover:bg-themeColor disabled:hover:bg-opacity-50 disabled:cursor-progress'
			 	type="button"
			 	onClick={handleSubmit} 
				disabled={!stripe || loading}
			>
					Pay $19
			</button>
			{errorMessage && <div className="mt-[35px] ml-auto mr-auto error rounded px-4 p-2 text-white bg-red-400 animate-fadeIn">{errorMessage}</div>}
		</div>
	)
}

export default Payment