import React from 'react';

const SkeletonLoader = ({ height, width }) => {
  return (
    <div style={{ height, width }} className={`bg-gray-300 animate-pulse rounded-lg`}>
    </div>
  );
};

export default SkeletonLoader;
