import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

initializeApp({
  apiKey: "AIzaSyC8HPCFbD52BCYUtvF8t4yDVqSQZDeWWl0",
  authDomain: "usagrants-app.firebaseapp.com",
  projectId: "usagrants-app",
  storageBucket: "usagrants-app.appspot.com",
  messagingSenderId: "620619214921",
  appId: "1:620619214921:web:84fb6f2a7e6504d0a48f98"
})

const db = getFirestore()
const auth = getAuth()

export { db, auth }