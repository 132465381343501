export const grantSubCategoriesArray = [
  "Arts and Culture",
  "Transportation",
  "Research",
  "Employment",
  "Start-up",
  "Technology",
  "Economic Development",
  "Business",
  "Community Assistance",
  "Minorities/Demographics",
  "Personal Assistance",
  "Senior Citizens",
  "Veterans",
  "Women",
  "Edu-Students",
  "Utilities",
  "Travel & Tourism",
  "COVID-19",
  "International",
  "Environmental",
  "Training",
  "Leadership",
  "Science",
  "Research",
  "Child Development",
  "Employment",
  "Low Income",
  "Asian",
  "Hispanic",
  "Native American",
  "Pacific Islander",
  "Environmental",
  "Expand",
  "Cosmetology",
  "Minorities",
  "Disaster Prevention",
  "Disaster Relief",
  "Faith-Based",
  "Green",
  "Social Services",
  "Historical",
  "Rural"
]

export const grantSubCategories = grantSubCategoriesArray.map(subCategory => ({
  label: subCategory,
  id: subCategory.replace(/ /g, '_')
}))