import React, { useEffect, useState } from 'react';
import Payment from '../components/Payment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import SkeletonLoader from '../elements/SkeletonLoader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

const OrderPage = () => {
  const [formData, setFormData] = useState({
    cardholderName: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    ccNum: '',
    ccExpMonth: '',
    ccExpYear: '',
    ccCVV: '',
  })

	const [customer, setCustomer] = useState('')

  const searchParams = new URLSearchParams(window.location.search)

  const {
    firstName,
    lastName,
    streetAddress,
    zip,
    city,
    state,
    gender,
    age,
    citizenshipStatus,
    ethnicity,
    employmentStatus,
    phoneNumber,
    fundingType,
    amountNeeded,
    useDescription,
    urgency,
    email,
    password,
    agreeToTerms,
  } = Object.fromEntries(searchParams)

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntent, setPaymentIntent] = useState("");

	useEffect(() => {

    if(email) {
      setCustomer(email)
    }

  }, [email])

	useEffect(() => {
		if(customer) {
      fetch(`${process.env.REACT_APP_PAYMENT_ENDPOINT_DEV}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          product: "Grant Funding: Access Fee",
          email: email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret)
          setPaymentIntent(data.paymentIntent)
        });
		}
  }, [customer]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'number' ? parseInt(value) : value,
    });
  };

  const validateForm = () => {
    // Add your validation logic here
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission
      console.log(formData);
    } else {
      // Show error messages or highlight invalid fields
    }
  };

  return (
		<div className="h-screen pr-[15px] pl-[15px] w-full pt-[150px] flex flex-col items-center">
            <div className="w-full max-w-[1202px] mx-auto p-6">
                <p className="header application__steps-title text-[28px]">
                    <span className="font-bold text-[28px]">Step 1 of 3:</span> Tell us about You and Your Funding Needs
                </p>

                <form
                    method="post"
                    target="_top"
                    className="mt-4"
                >
                    <div className="mt-4 mb-4 bg-[#EDF1F5] rounded-2xl w-full pt-[30px] pb-[30px] pl-[40px] pr-[40px]">
												<p className="text-[20px] mb-4 text-themeColorDark">Personal Information Summary</p>

												<div className='flex gap-2'>
													<label className="application__label">First Name:</label>
													<label className="application_value">{firstName}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Last Name:</label>
													<label className="application_value">{lastName}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Street Address:</label>
													<label className="application_value">{streetAddress}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">City:</label>
													<label className="application_value">{city}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">State:</label>
													<label className="application_value">{state}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">ZIP:</label>
													<label className="application_value">{zip}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Gender:</label>
													<label className="application_value">{gender}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Age:</label>
													<label className="application__label">{age}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Citizenship Status:</label>
													<label className="application_value">{citizenshipStatus}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Phone:</label>
													<label className="application_value">{phoneNumber}</label>
												</div>
                    </div>

                    <div className="mt-4 mb-4 bg-[#EDF1F5] rounded-2xl w-full pt-[30px] pb-[30px] pl-[40px] pr-[40px]">
												<p className="text-[20px] mb-4 text-themeColorDark">Funding Summary</p>

												<div className='flex gap-2'>
													<label className="application__label">How much money do you need?</label>
													<label className="application_value">{amountNeeded}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Briefly Describe How You Will Use The Money:</label>
													<label className="application_value">{fundingType}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">How Soon Do You Need The Money?</label>
													<label className="application_value">{urgency}</label>
												</div>
                    </div>

                    <div className="mt-4 mb-4 bg-[#EDF1F5] rounded-2xl w-full pt-[30px] pb-[30px] pl-[40px] pr-[40px]">
												<p className="text-[20px] mb-4 text-themeColorDark">User Account Info:</p>

												<div className='flex gap-2'>
													<label className="application__label">Username</label>
													<label className="application_value">{email}</label>
												</div>
												<div className='flex gap-2'>
													<label className="application__label">Password:</label>
													<label className="application_value">***The one you provided***</label>
												</div>
                    </div>

                    <div className="mt-4 mb-4 bg-[#EDF1F5] rounded-2xl w-full pt-[30px] pb-[30px] pl-[40px] pr-[40px]">
												<p className="text-[20px] mb-4 text-themeColorDark">Payment Information for the $19/month Access Fee:</p>
                        <p>(more than 46% off similar services)</p>
												<p className='mt-4'>Our access fee pays for unlimited access to grant applications, resources, and application assistance. It also pays for our grant funding researchers who provide you with the most up-to-date information available. <b>WE ALSO HAVE A GUARANTEE.</b> If you don't qualify for a Grant Program, you will be refunded immediately. Just provide us with the grant application you applied for and the response letter from the grantor. <b>So, EITHER 1) You qualify for a funding program or 2) You will have your payment refunded to you! Start Applying Today!</b></p>
												
                        <div className='mt-4 w-full'>
                          {
                            clientSecret
                            ?
                            <Elements options={options} stripe={stripePromise}>
                              <Payment customer={customer} password={password} paymentIntent={paymentIntent} />
                            </Elements>
                            :
                            <SkeletonLoader height={300} width={'100%'} />
                          }
                        </div>
                    </div>
                </form>
            </div>
        </div>
  );
};

export default OrderPage;