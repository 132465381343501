import React, { useEffect, useState } from 'react'
import GrantsList from '../components/GrantsList'
import DeleteTextIcon from '../assets/icons/DeleteTextIcon'

import { InstantSearch, useSearchBox } from "react-instantsearch"
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter"
import { grantSubCategories, grantSubCategoriesArray } from '../constants/grantSubCategories'
import { grantCategories, grantCategoriesArray } from '../constants/grantCategories'
import { grantDeadlines, grantDeadlinesArray } from '../constants/grantDeadlines'
import { grantStates, grantStatesArray } from '../constants/grantStates'
import { getGrantDeadline } from '../helpers/utils'
const Typesense = require('typesense');

const SearchGrants = ({ user }) => {

	const currentMonth = new Date().toLocaleString('default', { month: 'short', year: 'numeric' });
	const [filterBy, setFilterBy] = useState('');

	const currentISODate = new Date().toISOString(); // Current date in ISO format

	const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
		server: {
			apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
			nodes: [
				{
					host: process.env.REACT_APP_TYPESENSE_HOST,
					port: "",
					path: "",
					protocol: "https",
				},
			],
			cacheSearchResultsForSeconds: 2 * 60,
		},
		
		additionalSearchParameters: {
			query_by: "grantTitle",
			per_page: 20,
			page: 1,
			filter_by: filterBy,
			sort_by: "grantDeadline:asc",
		},
	})

	const searchClient = typesenseInstantsearchAdapter.searchClient

	const SearchBox = () => {

		const { refine } = useSearchBox()

		const [keywordSearch, setKeywordSearch] = useState('')

		useEffect(() => {
			refine(keywordSearch)
			// eslint-disable-next-line
		}, [keywordSearch])

		const handleChangeKeywordSearch = (e) => {
			setKeywordSearch(e.target.value)
		}

		return (
			<>
				<input
					type="text"
					className="w-full text-[20px] border-[1px] border-gray-500 rounded p-2 pl-4 pr-4"
					onChange={handleChangeKeywordSearch}
					value={keywordSearch}
				/>
				{
					keywordSearch &&
					<p
						className='absolute bottom-[12px] right-[10px] cursor-pointer'
						onClick={() => setKeywordSearch('')}
					>
						<DeleteTextIcon />
					</p>
				}
			</>
		)
	}

	const [grantFilters, setGrantFilters] = useState({
		grantCategories: [],
		grantSubCategories: [],
		grantDeadline: [],
		grantOnlyAvailableState: [],
	})

	const handleToggleFilter = (filterType, filterValue) => {
		let filters = { ...grantFilters }

		if (Array.isArray(filterValue)) {
			filterValue.forEach((value) => {
				if (!filters[filterType].includes(value)) {
					filters[filterType].push(value)
				} else {
					filters[filterType] = filters[filterType].filter((v) => v !== value)
				}
			})
		} else {
			if (!filters[filterType].includes(filterValue)) {
				filters[filterType].push(filterValue)
			} else {
				filters[filterType] = filters[filterType].filter((v) => v !== filterValue)
			}
		}

		setGrantFilters(filters)
	
		const updatedFilterBy = Object.entries(filters)
			.filter(([_, value]) => value.length > 0)
			.map(([key, value]) => `${key}: [${value.join(",")}]`)
			.join(" && ")
	
			setFilterBy(updatedFilterBy)
	}

	const handleToggleAllFilters = (filterType, array) => {
		let filters = { ...grantFilters }
	
		if (filters[filterType].length === array.length) {
			filters[filterType] = []
		} else {
			filters[filterType] = array.slice()
		}
	
		setGrantFilters(filters)
	
		const updatedFilterBy = Object.entries(filters)
			.filter(([_, value]) => value.length > 0)
			.map(([key, value]) => `${key}: [${value.join(",")}]`)
			.join(" && ")
	
		setFilterBy(updatedFilterBy)
	}

	return (
		!!user &&
		<InstantSearch
			indexName="grants"
			searchClient={searchClient}
			future={
				{
					preserveSharedStateOnUnmount: true,
				}
			}
		>
			<div className="flex flex-col md:flex-row lg:flex-row w-full h-full">
				<div className="p-4 pr-0 w-full md:w-[20%] lg:w-[20%] mt-[125px] static md:fixed lg:fixed top-[10px] select-none h-full md:h-screen lg:h-screen md:pb-[100px] mlg:pb-[100px] md:overflow-x-scroll lg:overflow-x-scroll">
					<p className="text-[26px] font-semibold text-blue-950">SEARCH GRANTS</p>

					<div className="flex flex-col w-[95%]">
						<div className='mt-4 relative'>
							<p
								className='mb-2 text-[16px] text-gray-700 font-extrabold'
							>
								KEYWORD(S) SEARCH:
							</p>
							<SearchBox />
						</div>
					</div>

					<div className='mt-[25px] md:mt-[80px] lg:mt-[80px] relative'>
						<p className='mb-2 text-[20px] text-gray-700 font-extrabold'>CATEGORY:</p>

						<div className='p-2 border-gray-500 border-[1px] w-[95%] rounded-md'>
							<label className='ml-2 flex items-center' htmlFor="all-category">
								<input
									type="checkbox"
									name="all-category"
									id="all-category"
									className='transform scale-[1.3]'
									checked={grantFilters.grantCategories.length === grantCategoriesArray.length}
									onChange={() => handleToggleAllFilters('grantCategories', grantCategoriesArray)}
								/>
								<p className='pl-4'>All</p>
							</label>
							{
								grantCategories.map(({ id, label }) => (
									<label className='ml-6 flex items-center' htmlFor={id} key={`${id}-${Math.random()}`}>
										<input
											type="checkbox"
											name={id}
											id={id}
											className='transform scale-[1.3]'
											checked={grantFilters.grantCategories.includes(label)}
											onChange={() => handleToggleFilter('grantCategories', label)}
										/>
										<p className='pl-4'>{label}</p>
									</label>
								))
							}
						</div>
					</div>

					<div className='mt-[30px] relative'>
						<p className='mb-2 text-[20px] text-gray-700 font-extrabold'>SUB-CATEGORY:</p>

						<div className='p-2 border-gray-500 border-[1px] w-[95%] rounded-md h-[200px] overflow-y-scroll'>
							<label className='ml-2 flex items-center' htmlFor="all-sub-category">
								<input
									type="checkbox"
									name="all-sub-category"
									id="all-sub-category"
									className='transform scale-[1.3]'
									checked={grantFilters.grantSubCategories.length === grantSubCategoriesArray.length}
									onChange={() => handleToggleAllFilters('grantSubCategories', grantSubCategoriesArray)}
								/>
								<p className='pl-4'>All</p>
							</label>
							
							{
								grantSubCategories.map(({ id, label }) => (
									<label className='ml-6 flex items-center' htmlFor={id} key={`${id}-${Math.random()}`}>
										<input
											type="checkbox"
											name={id}
											id={id}
											className='transform scale-[1.3]'
											checked={grantFilters.grantSubCategories.includes(label)}
											onChange={() => handleToggleFilter('grantSubCategories', label)}
										/>
										<p className='pl-4'>{label}</p>
									</label>
								))
							}
						</div>
					</div>

					<div className='mt-[40px] relative'>
						<p className='mb-2 text-[20px] text-gray-700 font-extrabold'>BY DEADLINE:</p>

						<div className='p-2 border-gray-500 border-[1px] w-[95%] rounded-md'>
						<label className='ml-2 flex items-center' htmlFor="all-deadlines">
								<input
									type="checkbox"
									name="all-deadlines"
									id="all-deadlines"
									className='transform scale-[1.3]'
									checked={grantFilters.grantDeadline.length === (grantDeadlinesArray).map((d) => getGrantDeadline(d)).length}
									onChange={() => handleToggleAllFilters('grantDeadline', grantDeadlinesArray.map((d) => getGrantDeadline(d)))}
								/>
								<p className='pl-4'>All</p>
							</label>

							{
								grantDeadlines.map(({ id, label }) => (
									<label className='ml-6 flex items-center' htmlFor={id} key={`${id}-${Math.random()}`}>
										<input
											type="checkbox"
											name={id}
											id={id}
											className='transform scale-[1.3]'
											checked={grantFilters.grantDeadline.includes(getGrantDeadline(label))}
											onChange={() => handleToggleFilter('grantDeadline', getGrantDeadline(label))}
										/>
										<p className='pl-4'>{label}</p>
									</label>
								))
							}
						</div>
					</div>

					<div className='mt-[40px] relative'>
						<p className='mb-2 text-[20px] text-gray-700 font-extrabold'>BY STATE:</p>

						<div className='p-2 border-gray-500 border-[1px] w-[95%] rounded-md h-[200px] overflow-y-scroll'>
							<label className='ml-2 flex items-center' htmlFor="all-states">
								<input
									type="checkbox"
									name="all-states"
									id="all-states"
									className='transform scale-[1.3]'
									checked={grantFilters.grantOnlyAvailableState.length === grantStatesArray.length}
									onChange={() => handleToggleAllFilters('grantOnlyAvailableState', grantStatesArray)}
								/>
								<p className='pl-4'>All</p>
							</label>

							{
								grantStates.map(({ id, label }) => (
									<label className='ml-6 flex items-center' htmlFor={id} key={`${id}-${Math.random()}`}>
										<input
											type="checkbox"
											name={id}
											id={id}
											className='transform scale-[1.3]'
											checked={grantFilters.grantOnlyAvailableState.includes(label)}
											onChange={() => handleToggleFilter('grantOnlyAvailableState', label)}
										/>
										<p className='pl-4'>{label}</p>
									</label>
								))
							}
						</div>
					</div>
				</div>
				<div className="mt-[12] relative md:top-[125px] lg:top-[125px] h-fit ml-auto md:pt-[20px] lg:pt-[20px] p-2 pb-4 md:pr-4 lg:pr-4 md:pl-[15px] lg:pl-[15px] w-full md:flex-[0.8] lg:flex-[0.8] overflow-hidden">
						{
							Object.values(grantFilters).map((filter) => filter.length>0).includes(true)
							&&
							<div className="absolute md:fixed lg:fixed w-full top-[-20px] md:top-[110px] lg:top-[110px] pl-2 pt-6 pb-2 md:backdrop-blur-md lg:backdrop-blur-md text-sm md:text-lg lg:text-lg">
									<p className='text-black animate-fadeIn opacity-0'><b>Filtered grants.</b> Remove any filters to load all grants.</p>
							</div>
						}
					<div className='mt-[25px]'>
						<GrantsList />
					</div>
				</div>
			</div>
		</InstantSearch>
	)
}

export default SearchGrants