import { signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import { auth } from '../configs/firebase/config'
import { navigate } from 'wouter/use-location';

const LoginPage = () => {

	const [loading, setLoading] = useState(false)

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleUserLogin = () => {
		signInWithEmailAndPassword(auth, email, password)
		.then((user) => {
			if(user) {
				navigate('/grants')
			}
		})
	}

	return (
		<div className="h-screen p-4 grid place-items-center">
			<div className='flex flex-col p-8 rounded-lg shadow-md border'>
				<p className='mb-6 text-2xl font-bold text-themeColorDark'>Login to continue</p>
				<div>
					<label htmlFor="firstName" className="application__label">Email:</label>
					<input type="email" className='application__input' value={email} onChange={(e) => setEmail(e.target.value)} />
				</div>
				<div className='mt-2'>
					<label htmlFor="firstName" className="application__label">Password:</label>
					<input type="password" className='application__input' value={password} onChange={(e) => setPassword(e.target.value)} />
				</div>
				<div className="flex justify-center mt-4">
					<button
					onClick={handleUserLogin}
						className=" bg-themeColor hover:bg-themeColorDark text-white font-bold py-2 px-4 rounded disabled:opacity-80 disabled:cursor-not-allowed disabled:bg-themeColor select-none"
						disabled={loading}
					>
							{loading ? "Submitting..." : "Submit"}
					</button>
				</div>
			</div>
		</div>
	)
}

export default LoginPage