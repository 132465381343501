import { Router, Route } from 'wouter'
import SearchGrants from './pages/SearchGrants';
import GrantDetailsPage from './pages/GrantDetailsPage';
import Header from './components/Header';
import RegistrationPage from './pages/RegistrationPage';
import OrderPage from './pages/OrderPage';
import LoginPage from './pages/LoginPage';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { navigate } from 'wouter/use-location';
import { auth } from './configs/firebase/config';

const App = () => {

  const [user, setUser] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if(user) {
        setUser(user)
        if(!window.location.pathname.includes('/grants')) {
          navigate('/grants')
        }
      } else {
        if(!['/order', '/register'].includes(window.location.pathname)) {
          navigate('/login')
        }
      }
    })
  }, [])

  return (
    <Router>
      <Header />
      <Route path="/grants">
        <SearchGrants user={user} />
      </Route>
      <Route path="/grants/:id" component={GrantDetailsPage} user={user} />
      <Route path="/register" component={RegistrationPage} />
      <Route path="/order" component={OrderPage} />
      <Route path="/login" component={LoginPage} />
    </Router>
  );
}

export default App;
