export const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + ' ...'
  }
  return text
}

export const removeEscapeCharacters = (inputString) => {
  return inputString ? inputString.replace(/\\/g, '') : ''
}

export const getGrantDeadline = (option) => {
  const currentDate = new Date()
  let deadlineDates = []
  let noDeadline = false

  switch (option) {
    case "No Deadline":
      noDeadline = true
      break
    case "This Month":
      deadlineDates = [new Date(currentDate)]
      break
    case "1-3 Months":
      deadlineDates = [
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, currentDate.getDate()),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate())
      ]
      break
    case "4-6 Months":
      deadlineDates = [
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 4, currentDate.getDate()),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 5, currentDate.getDate()),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, currentDate.getDate())
      ]
      break
    case "7-9 Months":
      deadlineDates = [
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 7, currentDate.getDate()),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 8, currentDate.getDate()),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 9, currentDate.getDate())
      ]
      break
    case "10-12 Months":
      deadlineDates = [
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 10, currentDate.getDate()),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 11, currentDate.getDate()),
        new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate())
      ]
      break
    default:
      deadlineDates = []
  }

  if (noDeadline) {
    return 'No Deadline' // return if no deadline
  }

  if (deadlineDates.length > 0) {
    const monthAbbreviations = [
      "Jan", "Feb", "Mar", "Apr",
      "May", "Jun", "Jul", "Aug",
      "Sep", "Oct", "Nov", "Dec"
    ]

    return deadlineDates.map(date => {
      const month = monthAbbreviations[date.getMonth()]
      const year = date.getFullYear()
      return `${month} ${year}`
    }).join(", ")
  }

  return null // Invalid option
}

