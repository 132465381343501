import React, { useEffect, useState } from 'react'
import { removeEscapeCharacters } from '../helpers/utils'
import Loading from './Loading'
import { useHits, useInstantSearch, usePagination } from "react-instantsearch"
import { useLocation } from 'wouter'

const GrantsList = () => {

	const HitsWrapper = () => {
		const { hits } = useHits()
		const { status } = useInstantSearch()
		const { currentRefinement, nbPages, refine } = usePagination()

		// eslint-disable-next-line
		const [location, navigate] = useLocation()

		const [isInitialized, setIsInitialized] = useState(false)

		useEffect(() => {
			if(status === 'idle') {
				setIsInitialized(true)
			}
		}, [status])

		const handleRedirectGrantListing = (hit) => {
			navigate(`/grants/${hit?.id}`)
		}

		const filterAndSortHits = (hits) => {
			const currentDate = new Date();
			return hits
				// .filter(hit => hit.grantDeadline !== 'No Deadline' || new Date(hit.grantDeadline) >= currentDate)
				// .sort((a, b) => {
				// 	return new Date(a.grantDeadline) - new Date(b.grantDeadline);
				// });
		};

		return (
			<div className="w-full">
				{
					status === 'idle' && isInitialized
					?
						<>
							{
								hits.length>0
								?
								<>
									<Pagination
										position='top'
										pagination={{
											currentRefinement,
											nbPages,
											refine,
										}}
									/>
									<div className="w-full flex border-[1px] border-r-0 border-l-0 border-b-0 border-gray-300 bg-gray-200">
										<span className='header-cell-first grid font-bold'>Grant Title</span>
										<span className='header-cell grid font-bold'>Grant Amount</span>
										<span className='header-cell hidden md:grid lg:grid font-bold'>Grant Description</span>
										<span className='header-cell grid font-bold'>Grant Deadline</span>
										{/* <span className='header-cell font-bold'>Grant Link</span> */}
									</div>
									<div className="overflow-y-scroll scrollbar-hide">
										{
											filterAndSortHits(hits).map((hit) => (
												<div
													className={`w-full flex ${
														hit.__position % 2 === 0 ? 'bg-gray-100' : ''
													}`}
													key={hit.id}
												>
													<span className='cell-first underline text-textHighlightedColor font-semibold cursor-pointer text-[14px] md:text-base lg:text-base' onClick={() => handleRedirectGrantListing(hit)}>
														<div className='two-line-ellipsis'>{removeEscapeCharacters(hit.grantTitle) || 'N/A'}</div>
													</span>
													<span className='cell'>
														<div className='two-line-ellipsis'>{removeEscapeCharacters(hit.grantAmount) || 'N/A'}</div>
													</span>
													<span className='cell hidden md:block lg:block'>
														<div className='two-line-ellipsis'>{removeEscapeCharacters(hit.grantDescription) || 'N/A'}</div>
													</span>
													<span className='cell'>
														<div className='two-line-ellipsis'>{removeEscapeCharacters(hit.grantDeadline) || 'N/A'}</div>
													</span>
												</div>
											))
										}
									</div>
									<Pagination
										position='bottom'
										pagination={{
											currentRefinement,
											nbPages,
											refine,
										}}
									/>
								</>
								:
								<div>
									<span className='rounded-lg grid place-items-center h-[350px] bg-gray-200 text-[30px]'>
										<div className="flex items-center">
											<span className='grid place-items-center text-white w-[40px] h-[40px] rounded-full bg-black text-[25px] font-bold'>i</span>
											<p className='ml-4'>No results found.</p>
										</div>
									</span>
								</div>
							}
						</>
					:
					(
						<Loading show={status === 'loading' || status === 'stalled'}/>
					)
				}
			</div>
		)
	}

	const Pagination = ({ position, pagination }) => {

		const { currentRefinement, nbPages, refine } = pagination

		const currentPage = currentRefinement+1

		const handleNextPageClick = () => {
			const nextPage = currentRefinement + 1
			if (nextPage < nbPages) {
				refine(nextPage)
			}
		}
		
		const handlePrevPageClick = () => {
			const prevPage = currentRefinement - 1
			if (prevPage >= 0) {
				refine(prevPage)
			}
		}

		const hasNextPage = currentPage < nbPages - 1
		const hasPrevPage = currentPage > 1

		const topStyles = 'flex justify-center ml-auto mr-auto rounded-t-lg select-none w-full bg-themeColor text-white'
		const bottomStyles = 'flex justify-center ml-auto mr-auto rounded-b-lg select-none w-full bg-themeColor text-white'
		return (
			<div className={position === 'top' ? topStyles : bottomStyles}>
				<button 
					className='flex p-2 hover:underline'
					onClick={handlePrevPageClick}
					style={{
						opacity: !hasPrevPage ? '50%' : '100%',
						textDecoration: !hasPrevPage ? 'none' : '',
						cursor: !hasPrevPage ? 'not-allowed' : 'pointer',
					}}
					disabled={!hasPrevPage}
				>
					Prev Page
				</button>
				<button 
					className='flex p-2 hover:underline'
					onClick={handleNextPageClick}
					style={{
						opacity: !hasNextPage ? '50%' : '100%',
						textDecoration: !hasNextPage ? 'none' : '',
						cursor: !hasNextPage ? 'not-allowed' : 'pointer',
					}}
					disabled={!hasNextPage}
				>
					Next Page
				</button>
				<p className='absolute right-[15px] p-[2px] pl-[10px] pr-[10px] rounded mt-[5px] mr-2 font-semibold border-[1px] border-white'>{currentPage} / {nbPages}</p>
			</div>
		)
	}

	// const { status } = useInstantSearch()

	return (
		<div className='w-full flex flex-col h-full'>
			<HitsWrapper
				hits={0}
				status={true}
			/>
		</div>
	)
}

export default GrantsList