import React, { useEffect, useState } from 'react'
import TypesenseClient from 'typesense'
import { useLocation, useParams } from 'wouter'

const GrantDetailsPage = () => {
  const { id } = useParams()
  const [grantData, setGrantData] = useState(null)

  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [validImage, setValidImage] = useState(true)
  
  // eslint-disable-next-line
  const [location, navigate] = useLocation()

  const typesenseConfig = {
			apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
			nodes: [
				{
					host: process.env.REACT_APP_TYPESENSE_HOST,
					port: "",
					path: "",
					protocol: "https",
				},
			],
			cacheSearchResultsForSeconds: 2 * 60,
  }

  const collectionName = 'grants'
  const typesense = new TypesenseClient.Client(typesenseConfig)

  const searchGrantById = async (grantId) => {
    try {
			const searchResult = await typesense
      .collections(collectionName)
      .documents()
      .search({
        q: '*',
				filter_by: `id:=${id}`
      })

      const foundGrant = searchResult.hits[0]

      if (foundGrant) {
        setGrantData(foundGrant?.document)
      } else {
        setGrantData(null)
      }
    } catch (error) {
      console.error('Error searching for grant:', error)
    }
  }

	useEffect(() => {
		searchGrantById()
    // eslint-disable-next-line
	}, [])

  useEffect(() => {
		console.log(grantData)
	}, [grantData])

  return (
    <div className="mt-[100px] pt-[80px] p-[35px]">
			{
        grantData !== null
        ?
        <>
          <p className='mb-[18px] text-[18px] pb-[5px] uppercase border-b-2 border-white font-semibold hover:border-black w-fit select-none cursor-pointer transition-all' onClick={() => navigate('/grants')}>{'<'} Back to search</p>
          <div className='flex w-full'>
            <div className='flex flex-col md:flex-[0.6] lg:flex-[0.6] md:pr-[35px] lg:pr-[35px]'>
              <div className='flex md:hidden lg:hidden mb-4'>
                {
                  !isImageLoaded
                  &&
                  <svg width="200" height="150" viewBox="0 0 200 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M75.0252 43.75C75.0252 48.7228 73.0497 53.4919 69.5334 57.0083C66.0171 60.5246 61.248 62.5 56.2752 62.5C51.3024 62.5 46.5332 60.5246 43.0169 57.0083C39.5006 53.4919 37.5252 48.7228 37.5252 43.75C37.5252 38.7772 39.5006 34.0081 43.0169 30.4917C46.5332 26.9754 51.3024 25 56.2752 25C61.248 25 66.0171 26.9754 69.5334 30.4917C73.0497 34.0081 75.0252 38.7772 75.0252 43.75Z" fill="#AAAAAA"/>
                    <path d="M18.75 0C13.7772 0 9.00805 1.97544 5.49175 5.49175C1.97544 9.00806 0 13.7772 0 18.75L0 131.25C0 136.223 1.97544 140.992 5.49175 144.508C9.00805 148.025 13.7772 150 18.75 150H181.25C186.223 150 190.992 148.025 194.508 144.508C198.025 140.992 200 136.223 200 131.25V18.75C200 13.7772 198.025 9.00806 194.508 5.49175C190.992 1.97544 186.223 0 181.25 0H18.75ZM181.25 12.5C182.908 12.5 184.497 13.1585 185.669 14.3306C186.842 15.5027 187.5 17.0924 187.5 18.75V93.75L140.313 69.4125C139.14 68.8253 137.813 68.6216 136.519 68.8302C135.224 69.0388 134.028 69.6492 133.1 70.575L86.725 116.95L53.475 94.8C52.2745 94.0007 50.8345 93.6413 49.3992 93.7825C47.9639 93.9238 46.6217 94.5571 45.6 95.575L12.525 125V131.75L12.5 131.25V18.75C12.5 17.0924 13.1585 15.5027 14.3306 14.3306C15.5027 13.1585 17.0924 12.5 18.75 12.5H181.25Z" fill="#AAAAAA"/>
                  </svg>
                }
                {
                  validImage
                  &&
                  <img className='w-[500px]' src={grantData?.grantImage} alt="grant logo" onLoad={() => setIsImageLoaded(true)} onError={(e) => setValidImage(false)} />
                }
              </div>
              <p className='flex w-full md:w-[60%] lg:w-[60%] text-[40px] font-medium'>{grantData?.grantTitle}</p>
              <div className='mt-[50px] flex text-[20px]'>
                <p className='font-semibold'>Grant Link:</p>
                <a className='pl-4 underline text-blue-800' href={grantData?.grantLink} target="_blank" rel="noreferrer">VIEW GRANT</a>
              </div>
              <div className='mt-[50px] flex flex-col'>
                <p className='font-semibold text-[18px]'>CATEGORIES:</p>
                <p className='pt-2 font-medium uppercase text-[16px]'>{grantData?.grantCategories?.toString().replaceAll(',', `, `)}</p>
              </div>
                {
                  grantData?.grantSubCategories
                  &&
                    <div className='mt-[50px] flex flex-col'>
                      <p className='font-semibold text-[18px]'>SUB CATEGORIES:</p>
                        <p className='pt-2 font-medium uppercase text-[16px]'>{grantData?.grantSubCategories?.toString().replaceAll(',', `, `)}</p>
                    </div>
                }
              <div className='mt-[80px] flex flex-col'>
                <p className='font-semibold text-[20px]'>Description:</p>
                <p className='pt-2 text-[18px]'>{grantData?.grantDescription}</p>
              </div>

              <div className='w-full flex flex-col md:hidden lg:hidden'>
                <div className='mt-[50px] w-full rounded-lg overflow-hidden border-[1px] border-gray-300'>
                  <p className='p-4 pt-2 pb-2 text-center bg-themeColor uppercase text-[22px] font-semibold text-white'>Grant Deadline</p>
                  <p className='p-4 text-center text-[20px] font-medium'>{grantData?.grantDeadline}</p>
                </div>

                <div className='mt-[50px] w-full rounded-lg overflow-hidden border-[1px] border-gray-300'>
                  <p className='p-4 pt-2 pb-2 text-center bg-themeColor uppercase text-[22px] font-semibold text-white'>Grant Amount</p>
                  <p className='p-4 text-center text-[20px] font-medium'>{grantData?.grantAmount}</p>
                </div>

                <div className='mt-[50px] w-full rounded-lg overflow-hidden border-[1px] border-gray-300'>
                  <p className='p-4 pt-2 pb-2 text-center bg-themeColor uppercase text-[22px] font-semibold text-white'>Grant States</p>
                  <p className='p-4 text-center text-[20px] font-medium'>{grantData?.grantOnlyAvailableState}</p>
                </div>
              </div>
            </div>
            <div className='flex-col flex-[0.4] items-center hidden md:flex lg:flex'>
              {
                !isImageLoaded
                &&
                <svg width="200" height="150" viewBox="0 0 200 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M75.0252 43.75C75.0252 48.7228 73.0497 53.4919 69.5334 57.0083C66.0171 60.5246 61.248 62.5 56.2752 62.5C51.3024 62.5 46.5332 60.5246 43.0169 57.0083C39.5006 53.4919 37.5252 48.7228 37.5252 43.75C37.5252 38.7772 39.5006 34.0081 43.0169 30.4917C46.5332 26.9754 51.3024 25 56.2752 25C61.248 25 66.0171 26.9754 69.5334 30.4917C73.0497 34.0081 75.0252 38.7772 75.0252 43.75Z" fill="#AAAAAA"/>
                  <path d="M18.75 0C13.7772 0 9.00805 1.97544 5.49175 5.49175C1.97544 9.00806 0 13.7772 0 18.75L0 131.25C0 136.223 1.97544 140.992 5.49175 144.508C9.00805 148.025 13.7772 150 18.75 150H181.25C186.223 150 190.992 148.025 194.508 144.508C198.025 140.992 200 136.223 200 131.25V18.75C200 13.7772 198.025 9.00806 194.508 5.49175C190.992 1.97544 186.223 0 181.25 0H18.75ZM181.25 12.5C182.908 12.5 184.497 13.1585 185.669 14.3306C186.842 15.5027 187.5 17.0924 187.5 18.75V93.75L140.313 69.4125C139.14 68.8253 137.813 68.6216 136.519 68.8302C135.224 69.0388 134.028 69.6492 133.1 70.575L86.725 116.95L53.475 94.8C52.2745 94.0007 50.8345 93.6413 49.3992 93.7825C47.9639 93.9238 46.6217 94.5571 45.6 95.575L12.525 125V131.75L12.5 131.25V18.75C12.5 17.0924 13.1585 15.5027 14.3306 14.3306C15.5027 13.1585 17.0924 12.5 18.75 12.5H181.25Z" fill="#AAAAAA"/>
                </svg>
              }
              {
                validImage
                &&
                <img className='w-[500px]' src={grantData?.grantImage} alt="grant logo" onLoad={() => setIsImageLoaded(true)} onError={(e) => setValidImage(false)} />
              }

              <div className='mt-[50px] w-full rounded-lg overflow-hidden border-[1px] border-gray-300'>
                <p className='p-4 pt-2 pb-2 text-center bg-themeColor uppercase text-[22px] font-semibold text-white'>Grant Deadline</p>
                <p className='p-4 text-center text-[20px] font-medium'>{grantData?.grantDeadline}</p>
              </div>

              <div className='mt-[50px] w-full rounded-lg overflow-hidden border-[1px] border-gray-300'>
                <p className='p-4 pt-2 pb-2 text-center bg-themeColor uppercase text-[22px] font-semibold text-white'>Grant Amount</p>
                <p className='p-4 text-center text-[20px] font-medium'>{grantData?.grantAmount}</p>
              </div>

              <div className='mt-[50px] w-full rounded-lg overflow-hidden border-[1px] border-gray-300'>
                <p className='p-4 pt-2 pb-2 text-center bg-themeColor uppercase text-[22px] font-semibold text-white'>Grant States</p>
                <p className='p-4 text-center text-[20px] font-medium'>{grantData?.grantOnlyAvailableState}</p>
              </div>
            </div>
          </div>
        </>
        :
				<div className='h-[600px] w-full grid place-items-center'>
          <svg aria-hidden="true" className="w-[80px] h-[80px] mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
        </div>
      }
    </div>
  )
}

export default GrantDetailsPage
