import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'wouter';

function RegistrationPage() {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        streetAddress: '',
        zip: '',
        city: '',
        state: '',
        gender: '',
        age: '',
        citizenshipStatus: '',
        ethnicity: '',
        employmentStatus: '',
        phoneNumber: '',
        fundingType: '',
        amountNeeded: '',
        useDescription: '',
        urgency: '',
        email: '',
        password: '',
        agreeToTerms: false,
    })

    const [location, navigate] = useLocation()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log()
    }, [formData])

    
      const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault()

        const isAllDefined = Object.values(formData).every(value => value !== undefined);

        if(isAllDefined) {
            setLoading(true)

            // Construct the query string from the formData
            const queryString = new URLSearchParams(formData).toString()

            document.querySelector('#hubSpotForm').submit()

            setTimeout(() => {
                // Redirect to the /order page with the query string
                navigate(`/order?${queryString}`)
            }, 3000)
        }
      }

    const FundingOption = ({ name, value, onChange }) => {
        return (
            <label className="flex items-center space-x-3 cursor-pointer">
            <input
                type="radio"
                name="fundingType"
                value={value}
                onChange={onChange}
                className="form-radio h-5 w-5 text-green-500"
                checked={formData.fundingType === value}
            />
            <span className="text-gray-700">{name}</span>
            </label>
        );
    };

    const fundingOptions = [
        { name: 'Business', value: 'Business' },
        { name: 'Community Assistance', value: 'Community Assistance' },
        { name: 'Education', value: 'Education' },
        { name: 'Home Buyers', value: 'Home Buyers' },
        { name: 'Home Repairs', value: 'Home Repairs' },
        { name: 'Inventions', value: 'Inventions' },
        { name: 'Minorities / Demographic', value: 'Minorities / Demographic' },
        { name: 'Misc', value: 'Misc' },
        { name: 'Non-Profit', value: 'Non-Profit' },
        { name: 'Personal (Bills, Rent, Utilities, etc.)', value: 'Personal (Bills, Rent, Utilities, etc.)' },
        { name: 'Real Estate', value: 'Real Estate' },
    ]

    const stateOptions = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL",
        "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT",
        "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI",
        "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ]


    const validateInputFields = () => {
        // Check each property in the object
        for (const key in formData) {
            if (formData[key] === '' || formData[key] === undefined || formData[key] === null) {
                // If any property is empty, undefined, or null, return false
                return false;
            }
        }
        // If all properties are filled, return true
        return true;
    }
      

    return (
        <div className="h-screen pr-[15px] pl-[15px] w-full pt-[150px] flex flex-col items-center">
            <div className="w-full max-w-[1202px] mx-auto p-6">
                <p className="header application__steps-title text-[28px]">
                    <span className="font-bold text-[28px]">Step 1 of 3:</span> Tell us about You and Your Funding Needs
                </p>

                <form
                    onSubmit={handleSubmit}
                    className="mt-4"
                >
                    <div className="bg-[#EDF1F5] rounded-2xl w-full pt-[30px] pb-[30px] pl-[40px] pr-[40px]">
                        <div className="flex gap-[20px] justify-between">
                            <div className="w-full mb-4 flex flex-col">
                                <label htmlFor="firstName" className="application__label">First Name:</label>
                                <input  
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  className="f-name application__input"
                                  required
                                  pattern="[a-zA-Z']+"
                                  title="Please use only letters"
                                  value={formData.firstName}
                                  onChange={handleInputChange}
                                />
                            </div>

                            <div className="w-full mb-4 flex flex-col">
                                <label htmlFor="lastName" className="application__label">Last Name:</label>
                                <input 
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  className="application__input"
                                  required
                                  pattern="[a-zA-Z']+"
                                  title="Please use only letters"
                                  value={formData.lastName}
                                  onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="w-full mb-4 flex flex-col">
                            <label htmlFor="streetAddress" className="application__label">Street Address:</label>
                            <input
                                type="text"
                                name="streetAddress"
                                id="streetAddress"
                                className="application__input"
                                required
                                value={formData.streetAddress}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="w-full flex gap-[20px] justify-between">
                            <div className="mb-4 flex flex-col">
                                <label htmlFor="zip" className="application__label">Zip:</label>
                                <input 
                                  type="text"
                                  name="zip"
                                  id="zip"
                                  className="application__input"
                                  required
                                  value={formData.zip}
                                  onChange={handleInputChange}
                                />
                            </div>

                            <div className="w-full mb-4 flex flex-col">
                                <label htmlFor="city" className="application__label">City:</label>
                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  className="application__input"
                                  required
                                  value={formData.city}
                                  onChange={handleInputChange}
                                />
                            </div>

                            <div className="w-full mb-4 flex flex-col">
                                <label htmlFor="state" className="application__label">State:</label>
                                <select
                                  className='application__input h-full' 
                                  id="application__input"
                                  name="state"
                                  value={formData.state}
                                  onChange={handleInputChange}
                                  required
                                >
                                    <option value="" disabled selected>Select State</option>
                                    {stateOptions.map((state, index) => (
                                        <option key={index} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 bg-[#EDF1F5] rounded-2xl w-full pt-8 pb-8 pl-10 pr-10">
                        {/* First Row: Gender and Age */}
                        <div className="flex flex-col md:flex-row gap-5">
                            <div className="w-full">
                                <label className="application__label">Gender:</label>
                                <select 
                                  id="gender"
                                  name="gender"
                                  className="application__input h-[43px]"
                                  value={formData.gender}
                                  onChange={handleInputChange}
                                  required
                                >
                                    <option value="default" disabled selected>Select...</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="w-full">
                                <label className="application__label">What is your age?</label>
                                <select 
                                  id="age" 
                                  name="age" 
                                  className="application__input h-[43px]" 
                                  required
                                  value={formData.age}
                                  onChange={handleInputChange}
                                >
                                    <option value="" disabled selected>Select...</option>
                                    <option value="18-25">18-25</option>
                                    <option value="26-34">26-34</option>
                                    <option value="35-49">35-49</option>
                                    <option value="50-65">50-65</option>
                                    <option value="66-80">66-80</option>
                                    <option value="80+">80 +</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col md:flex-row gap-5">
                            <div className="w-full">
                                <label className="application__label">Citizenship Status:</label>
                                <select 
                                  id="citizenshipStatus" 
                                  name="citizenshipStatus" 
                                  className="application__input h-[43px]" 
                                  required
                                  value={formData.citizenshipStatus} 
                                  onChange={handleInputChange} 
                                >
                                    <option value="" disabled selected>Select...</option>
                                    <option value="U.S. Citizen">U.S. Citizen</option>
                                    <option value="Resident Alien">Resident Alien</option>
                                    <option value="Green Card Holder">Green Card Holder</option>
                                    <option value="Permanent Resident">Permanent Resident</option>
                                    <option value="Not Sure">Not Sure</option>
                                </select>
                            </div>
                            <div className="w-full">
                                <label className="application__label">Ethnicity:</label>
                                <select id="ethnicity" name="ethnicity" className="application__input h-[43px]" required value={formData.ethnicity} onChange={handleInputChange}>
                                    <option value="" disabled selected>Select...</option>
                                    <option value="Asian American">Asian American</option>
                                    <option value="Black or African American">Black or African American</option>
                                    <option value="Hispanic">Hispanic</option>
                                    <option value="Latino">Latino</option>
                                    <option value="Middle Eastern American">Middle Eastern American</option>
                                    <option value="Multi-racial">Multi-racial</option>
                                    <option value="Native American">Native American</option>
                                    <option value="Native Hawaiian">Native Hawaiian</option>
                                    <option value="Other">Other</option>
                                    <option value="Pacific Islander">Pacific Islander</option>
                                    <option value="White">White</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col md:flex-row gap-5">
                            <div className="w-full">
                                <label className="application__label">Employment Status:</label>
                                <select id="employmentStatus" name="employmentStatus" className="application__input h-[43px]" required value={formData.employmentStatus} onChange={handleInputChange}>
                                    <option value="" disabled selected>Select...</option>
                                    <option value="Employed Full-Time">Employed Full-Time</option>
                                    <option value="Employed Part-Time">Employed Part-Time</option>
                                    <option value="Self Employed">Self Employed</option>
                                    <option value="Unemployed">Unemployed</option>
                                    <option value="Retired">Retired</option>
                                    <option value="Collecting Social Security">Collecting Social Security</option>
                                    <option value="Disabled">Disabled</option>
                                </select>
                            </div>
                            <div className="w-full">
                                <label className="application__label">Phone Number:</label>
                                <input 
                                  type="text" 
                                  name="phoneNumber" 
                                  id="phoneNumber"
                                  className="application__input"
                                  required
                                  value={formData.phoneNumber} 
                                  onChange={handleInputChange} 
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 bg-[#EDF1F5] rounded-2xl w-full pt-8 pb-8 pl-10 pr-10">
                        <div className="space-y-4">
                            <label htmlFor="urgency" className="application__label">
                                Choose which type of funding you are interested in:
                            </label>
                            {/* Funding Type Options */}
                            <div className="pt-4 pb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                {fundingOptions.map((option, index) => (
                                    <FundingOption
                                        key={index}
                                        name={option.name}
                                        value={option.value}
                                        onChange={handleInputChange}
                                    />
                                ))}
                            </div>

                            {/* Amount Needed */}
                            <div className="mb-4">
                                <p htmlFor="amountNeeded" className="application__label">
                                    How much money are you going to need?
                                </p>
                                <select
                                    name="amountNeeded"
                                    value={formData.amountNeeded}
                                    onChange={handleInputChange}
                                    className="application__input h-[43px]"
                                    required
                                >
                                    <option value="" disabled selected>Select...</option>
                                    <option value="$500-$10,000">$500-$10,000</option>
                                    <option value="$10,000 - $25,000">$10,000 - $25,000</option>
                                    <option value="$25,000 - $50,000">$25,000 - $50,000</option>
                                    <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                                    <option value="$100,000 or More">$100,000 or More</option>
                                </select>
                            </div>

                            {/* Use Description */}
                            <div className="mb-4">
                                <label htmlFor="useDescription" className="application__label">
                                    Briefly Describe How You Will Use The Money:
                                </label>
                                <textarea
                                    name="useDescription"
                                    value={formData.useDescription}
                                    onChange={handleInputChange}
                                    className="application__input"
                                    rows="6"
                                    required
                                ></textarea>
                            </div>

                            {/* Urgency */}
                            <div className="mb-4">
                                <label htmlFor="urgency" className="application__label">
                                    How Soon Do You Need The Money?
                                </label>
                                <textarea
                                    name="urgency"
                                    value={formData.urgency}
                                    onChange={handleInputChange}
                                    className="application__input"
                                    rows="6"
                                    required
                                ></textarea>
                            </div>

                            {/* Email */}
                            <div className="mb-4">
                                <label htmlFor="email" className="application__label">
                                    What Email Address Would You Like To Use As Your Username For Your Online Application Account?
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="application__input"
                                    placeholder="yourmail@example.com"
                                    required
                                />

                                <div className='mt-4 text-sm text-gray-500 italic'>
                                    <p>** Your email is safe. We hate spam as much as you do!!</p>
                                    <p>** Your Email will be your username for this website. Please double check it to be sure it is accurate!</p>
                                </div>
                            </div>

                            {/* Password */}
                            <div className="mb-4">
                                <label htmlFor="email" className="application__label">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    className="application__input"
                                    placeholder="********"
                                    required
                                />
                            </div>

                            {/* Password */}
                            <div className="mb-4">
                                <label htmlFor="email" className="application__label">
                                    Retype Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    className="application__input"
                                    placeholder="********"
                                    required
                                />
                            </div>

                            {/* Agreement */}
                            <div className="flex items-start mb-4">
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={formData.agreeToTerms}
                                    onChange={handleInputChange}
                                    className="mt-[3px] h-4 w-4 text-green-600"
                                />
                                <label htmlFor="agreeToTerms" className="ml-2 application__label">
                                    By clicking, I agree to the <a href="#" className="text-blue-500" target="_blank">Terms</a> of this site and am providing my electronic signature authorizing USAGrantApp to contact me by email, text, or phone regarding my funding applications service account with any new funding services or sources that may become available.
                                </label>
                            </div>
                        </div>
                     </div>
                    {/* Submit Button */}
                    <div className="flex justify-center mt-4">
                      <button
                        type="submit"
                        className=" bg-themeColor hover:bg-themeColorDark text-white font-bold py-2 px-4 rounded disabled:opacity-80 disabled:cursor-not-allowed disabled:bg-themeColor select-none"
                        disabled={!validateInputFields() || loading}
                      >
                          {loading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                </form>
            </div>

            {/* Hidden Form for HubSpot  */}
            <form
                id="hubSpotForm"
                method="POST"
                action="https://forms.hubspot.com/uploads/form/v2/45190073/d93680d9-a3f0-4f1a-a2a5-f39103141102"
                hidden
            >
               <input type="text" name="firstname"  value={formData.firstName} />
                <input type="text" name="lastname"  value={formData.lastName} />
                <input type="text" name="address"  value={formData.streetAddress} />
                <input type="text" name="zip" value={formData.zip} />
                <input type="text" name="city" value={formData.city} />
                <input type="text" name="state" value={formData.state} />
                <input type="text" name="gender"  value={formData.gender} />
                <input type="text" name="age" value={formData.age} />
                <input type="text" name="citizenship_status" value={formData.citizenshipStatus} />
                <input type="text" name="ethnicity"  value={formData.ethnicity} />
                <input type="text" name="employment_status" value={formData.employmentStatus} />
                <input type="text" name="phone"  value={formData.phoneNumber} />
                <input type="text" name="funding_type" value={formData.fundingType} />
                <input type="text" name="how_much_needed"  value={formData.amountNeeded} />
                <textarea name="how_will_you_use" value={formData.useDescription}></textarea>
                <input type="text" name="when_needed"  value={formData.urgency} />
                <input type="email" name="email" value={formData.email} />
                <input type="checkbox" name="agree_to_terms" checked={formData.agreeToTerms} />
            </form>
        </div>
    );
}

export default RegistrationPage;