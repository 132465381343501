export const grantDeadlinesArray = [
  "No Deadline",
  "This Month",
  "1-3 Months",
  "4-6 Months",
  "7-9 Months",
  "10-12 Months",
]

export const grantDeadlines = grantDeadlinesArray.map(deadline => ({
  label: deadline,
  id: deadline.replace(/ /g, '_')
}))